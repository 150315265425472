<template>
    <div class="map-tip-box">
        
        <!-- <table class="tip-table">
            <tr>
                <th colspan="2">
                    <span class="tip-name">{{tInfo.siteName}}</span>
                </th>
                <th style="color:#666666" class="name">更新时间：</th>
                <th>
                    <span :title="tInfo.updateTime">{{tInfo.updateTime}}</span>
                </th>
            </tr>
            <tr>
                <td class="name">服务类型：</td>
                <td class="nameVal" :title="tInfo.serveName">{{tInfo.serveName}}</td>
                <td class="name">当前状态：</td>
                <td v-if="tInfo.siteType===1" class="nameVal">
                    <span class="state-close" v-if="tInfo.status === 0">公厕</span>
                    <span class="state-open" v-if="tInfo.status === 1">公厕</span>
                    <span class="state-warn" v-if="tInfo.status === 2">公厕</span>
                </td>
                <td  v-if="tInfo.siteType===2" class="nameVal">
                    <template  v-if="tInfo.status === 0">  
                        <span class="state-close" v-if="tInfo.status === 0">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                    <template  v-if="tInfo.status === 1">  
                        <span class="state-open" v-if="tInfo.status === 1">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                    <template  v-if="tInfo.status === 2">  
                        <span class="state-warn" v-if="tInfo.status === 2">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                </td>
            </tr>
            <tr>
                <td class="name">联系人：</td>
                <td class="nameVal" :title="tInfo.staffName">{{tInfo.staffName}}</td>
                <td class="name">联系电话：</td>
                <td class="nameVal"  :title="tInfo.staffPhone">{{tInfo.staffPhone}}</td>
            </tr>
            <tr>
                <td class="name">所属项目：</td>
                <td class="nameVal" :title="tInfo.projectDeptName">{{tInfo.projectDeptName}}</td>
                <td class="name">联系电话：</td>
                <td class="nameVal" :title="tInfo.deptPhone">{{tInfo.deptPhone}}</td>
            </tr>
            <tr v-if="tInfo.siteType==2">
                <td class="name">箱体1：</td>
                <td class="nameVal">
                    <span class="state-open" v-if="boxItem[0] === 0">空</span>
                    <span  class="state-open" v-else-if="boxItem[0] === 1">半满</span>
                    <span class="state-warn" v-else-if="boxItem[0] === 2">满</span>
                    <span  v-else>暂无数据</span>
                </td>
                <td class="name">箱体2：</td>
                <td class="nameVal">
                    <span class="state-open" v-if="boxItem[1] === 0">空</span>
                    <span class="state-open" v-else-if="boxItem[1] === 1">半满</span>
                    <span class="state-warn" v-else-if="boxItem[1] === 2">满</span>
                    <span  v-else>暂无数据</span>
                </td>
            </tr>
            <tr>
                <td class="name">公厕地址：</td>
                <td colspan="3" class="nameVal" :title="tInfo.location">{{tInfo.location}}</td>
            </tr>
        </table> -->
        <el-row class="topRow">
            <el-col :span="10"><div class="grid-content tip-name">{{tInfo.siteName}}</div></el-col>
            <el-col :span="5"><div class="grid-content  name" style="color:#666666" >更新时间：</div></el-col>
            <el-col :span="9"><div class="grid-content  nameVal">{{tInfo.updateTime}}</div></el-col>
        </el-row>
        <el-table :data="tables" :stripe="true" :header-cell-style="tableHeaderColor" :cell-style="rowClass" v-if="tInfo.status==0">
            <el-table-column align="center" width="180px" prop="value" font-size="10px" label=""></el-table-column>
            <el-table-column align="center" width="130px" prop="man" :label="tInfo.status === 0 ? '男厕(离线)' : '男厕'"></el-table-column>
            <el-table-column align="center" width="130px" prop="women" :label="tInfo.status === 0 ? '女厕(离线)' : '女厕'"></el-table-column>
        </el-table>
        <el-table :data="table" :stripe="true" :header-cell-style="tableHeaderColor" :cell-style="rowClass" v-else>
            <el-table-column align="center" width="180px" prop="value" label=""></el-table-column>
            <el-table-column align="center" width="130px" prop="man" :label="tInfo.status === 0 ? '男厕(离线)' : '男厕'"></el-table-column>
            <el-table-column align="center" width="130px" prop="women" :label="tInfo.status === 0 ? '女厕(离线)' : '女厕'"></el-table-column>
        </el-table>
        <el-row>
            <el-col :span="4"><div class="grid-content name">服务类型：</div></el-col>
            <el-col :span="8"><div class="grid-content nameVal" :title="tInfo.serveName">{{tInfo.serveName}}</div></el-col>
            <el-col :span="3"><div class="grid-content name">男厕：</div></el-col>
            <el-col :span="3"><div class="grid-content  nameVal" >
                <template v-if="tInfo.workStatus===1" >
                    <span class="state-close" v-if="tInfo.manStatus === 0">未开启</span>
                    <span class="state-open" v-if="tInfo.manStatus === 1">正常运行</span>
                    <span class="state-warn" v-if="tInfo.manStatus === 2">报警</span>
                </template>
                <!-- <template  v-if="tInfo.siteType===2">
                    <template  v-if="tInfo.status === 0">  
                        <span class="state-close" v-if="tInfo.status === 0">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                    <template  v-if="tInfo.status === 1">  
                        <span class="state-open" v-if="tInfo.status === 1">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                    <template  v-if="tInfo.status === 2">  
                        <span class="state-warn" v-if="tInfo.status === 2">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                </template> -->
            </div></el-col>
            <el-col :span="3"><div class="grid-content name">女厕：</div></el-col>
            <el-col :span="3"><div class="grid-content  nameVal" >
                <template v-if="tInfo.workStatus===1" >
                    <span class="state-close" v-if="tInfo.womanStatus === 0">未开启</span>
                    <span class="state-open" v-if="tInfo.womanStatus === 1">正常运行</span>
                    <span class="state-warn" v-if="tInfo.womanStatus === 2">报警</span>
                </template>
                <!-- <template  v-if="tInfo.siteType===2">
                    <template  v-if="tInfo.status === 0">  
                        <span class="state-close" v-if="tInfo.status === 0">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                    <template  v-if="tInfo.status === 1">  
                        <span class="state-open" v-if="tInfo.status === 1">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                    <template  v-if="tInfo.status === 2">  
                        <span class="state-warn" v-if="tInfo.status === 2">公厕 /</span>
                        <span class="state-close" v-if="tInfo.transferPointStatus === 0"> 转运站</span>
                        <span class="state-open" v-if="tInfo.transferPointStatus === 1"> 转运站</span>
                        <span class="state-warn" v-if="tInfo.transferPointStatus === 2"> 转运站</span>
                    </template>
                </template> -->
            </div></el-col>
        </el-row>
        <el-row>
        <el-col :span="4"><div class="grid-content  name">联系人：</div></el-col>
        <el-col :span="8"><div class="grid-content  nameVal" :title="tInfo.serveName">{{tInfo.staffName}}</div></el-col>
        <el-col :span="4"><div class="grid-content  name">联系电话：</div></el-col>
        <el-col :span="8"><div class="grid-content  nameVal" :title="tInfo.serveName">{{tInfo.deptPhone}}</div></el-col>
        </el-row>
        <el-row>
        <el-col :span="4"><div class="grid-content  name">所属项目：</div></el-col>
        <el-col :span="8"><div class="grid-content  nameVal" :title="tInfo.serveName">{{tInfo.projectDeptName}}</div></el-col>
        <el-col :span="4"><div class="grid-content name">联系电话：</div></el-col>
        <el-col :span="8"><div class="grid-content  nameVal" :title="tInfo.serveName">{{tInfo.deptPhone}}</div></el-col>
        </el-row>
        <el-row v-if="tInfo.siteType==2">
        <el-col :span="4"><div class="grid-content  name">箱体1：</div></el-col>
        <el-col :span="8"><div class="grid-content  nameVal">
            <span class="state-open" v-if="boxItem[0] === 0">空</span>
                    <span  class="state-open" v-else-if="boxItem[0] === 1">半满</span>
                    <span class="state-warn" v-else-if="boxItem[0] === 2">满</span>
                    <span  v-else>暂无数据</span>
            </div></el-col>
        <el-col :span="4"><div class="grid-content  name">箱体2：</div></el-col>
        <el-col :span="8"><div class="grid-content  nameVal">
            <span class="state-open" v-if="boxItem[1] === 0">空</span>
                    <span class="state-open" v-else-if="boxItem[1] === 1">半满</span>
                    <span class="state-warn" v-else-if="boxItem[1] === 2">满</span>
                    <span  v-else>暂无数据</span>
            </div></el-col>
        </el-row>
        <el-row>
        <el-col :span="4"><div class="grid-content  name">公厕地址：</div></el-col>
        <el-col :span="19"><div class="grid-content  nameVal">{{tInfo.location}}</div></el-col>
        </el-row>
        
    </div>
</template>

<script>
import axios from "../../request"
import {tableHeaderColor,rowClass} from '@/utils/table'
export default {
    name: "MapStationTip",
    props: ["id"],
    data() {
        return {
            info: {},
            tInfo: {},
            boxItem: [],
            table: [
                {
                    value: "硫化氢(标准值：≤0.05ppm)",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "氨气(标准值：≤5ppm)",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "温度",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "湿度",
                    man: "待更新",
                    women: "待更新",
                },
            ],
             tables: [
                {
                    value: "硫化氢(标准值：≤0.05ppm)",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "氨气(标准值：≤ 5ppm)",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "温度",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "湿度",
                    man: "待更新",
                    women: "待更新",
                },
            ]
        };
    },
    methods: {
        tableHeaderColor({ row, rowIndex }) {
        return 'background-color:#D0E3FF;height:30px;color:#333333;font-size:12px'
        },
        rowClass({ row, rowIndex }) {
            return 'height:30px;font-weight: 500;color: #333333;font-size:5px'
        },
        async getStationData(id){
            let data = await axios.get(`/data/index/toilet/findById/${id}`)
            if(data){
                console.log(data)
                this.info = data;
                this.tInfo = data
                this.boxItem[0] = data.cubage
                this.boxItem[1] = data.cubage1 
                this.table[0].man = data.manYdrothion
                this.table[0].women = data.womanYdrothion
                this.table[1].man = data.manAmmoniaGas
                this.table[1].women = data.womanAmmoniaGas
                this.table[2].man = data.manTemperature
                this.table[2].women = data.womanTemperature
                this.table[3].man = data.manHumidity
                this.table[3].women = data.womanHumidity
            }
        }
    },
    watch: {
        id(val){
            this.getStationData(val)
        }
    },
    mounted(){
        this.getStationData(this.id)
    }
};
</script>

<style scoped lang="scss">
.map-tip-box {
    // position: absolute;
    // z-index: 100;
    // left: 1000px;
    // top: 100px;
    border: solid 3px $blue;
    border-radius: 5px;
    padding: 10px;
    background-color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    width: 440px;
}
// .tip-title {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border-bottom: solid 1px #E4E7ED;
//     padding-bottom: 5px;
//     margin-bottom: 5px;
//     @include font;
// }


// .tip-table {
//     border-collapse: collapse;
//     table-layout: fixed;
//     width: 100%;
//     th {
//         text-align: left;
//     }
//     th {
//         border-bottom: solid 1px #b7b7b7;
//     }
//     th:nth-child(1) {
//         color: #666666;
//         width: 200px;
//         white-space: nowrap;     
//             overflow: hidden;        
//             text-overflow: ellipsis; 
//     }
//     tr {
//         line-height: 3;
//         td:nth-child(2n-1) {
//             color: #666666;
//         }
//         td:nth-child(2) {
            
//              padding-right: 10px;
//         }
//         td{
            
//             white-space: nowrap;     
//             overflow: hidden;        
//             text-overflow: ellipsis; 

//         }
//     }
// }
.tip-name {
    border-left: solid 3px #3E88FF;
    color: #3E88FF;
    padding-left: 5px;
    font-weight: bold;
    font-size: 16px;
     white-space: nowrap;     
    overflow: hidden;        
    text-overflow: ellipsis;
}
.state-close{
    color: gray;
}
.state-open{
    color: #00da24;
}
.state-warn{
    color: #ff5955;
}
.el-row{
    margin: 10px 0 10px 0;
}
.topRow{
    padding: 10px 0px 15px 0px;
    border-bottom: solid 1px #e9ecf7;
    
}
.name{
//    text-align: left;
//    padding-left: 30px;
   color: #666666;
   font-size: 14px;
}
.nameVal{
   color: #333333;
    white-space: nowrap;     
    font-size: 14px;
    overflow: hidden;        
    text-overflow: ellipsis;
    // padding-right: 10px;
}
::v-deep.el-table--striped .el-table__body tr.el-table__row--striped td{
  background-color:#D0E3FF  !important;
}
::v-deep.el-table td, .el-table th{
    padding: 7px 0 !important;
}
</style>
