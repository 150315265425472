<template>
  <div class="mapbox"  >
    <div class="map-data-tip" ref="mapData">
      <div class="tip-type">
        <span class="dian1"></span>
        <span>正常</span>
      </div>
      <!-- <div class="tip-type">
        <span class="dian2"></span>
        <span>未开启</span>
      </div> -->

      <div class="tip-type">
        <span class="dian3"></span>
        <span>报警</span>
      </div>
      <div class="tip-type">
        <span class="dian4"></span>
        <span>离线</span>
      </div>
    </div>
    <div class="countDown">
      <h4>
        倒计时：<span class="clock">{{ count }}</span
        >秒
      </h4>
    </div>
    <div class="map-data-top" ref="mapData">
      <div class="tip-type">
        <img src="../../assets/img/guanbi.png" alt="" class="icont" />

        <span>关闭</span>
      </div>
      <!-- <el-divider></el-divider> -->
      <div class="tip-type">
        <img src="../../assets/img/weixiu.png" alt="" class="icont" />
        <span>维修</span>
      </div>

      <!-- <el-divider></el-divider> -->
      <div class="tip-type">
        <img src="../../assets/img/zhuanyunzhan.png" alt="" class="icont" />
        <span>转运站</span>
      </div>
      <div class="tip-type">
        <img src="../../assets/img/gongce.png" alt="" class="icont" />
        <span>公厕</span>
      </div>
    </div>
    <div id="map"></div>

    <div id="shroomTip" class="shroombox">
      <div class="shroomTip" v-if="dialogVisible">
        <MapStationTip :id="id"></MapStationTip>
      </div>
      <div class="shroomTips" v-if="dialogVisibles">
        <MapWashroomTip :id="id"></MapWashroomTip>
      </div>
    </div>
    <!-- <el-dialog
            :visible.sync="dialogVisible"
            :center="true"
            width="570px"
            :show-close="false"
            :modal="false"
            custom-class="clear-bc"
            top="10vh"
        >
        </el-dialog> -->
  </div>
</template> 

<script>
import custom from "../../assets/json/custom_map_config";
import MapIcon from "../../assets/js/IconOverlay";
import MapStationTip from "../map/MapStationTip";
import MapWashroomTip from "../map/MapWashRoomTip";
import axios from "../../request";
import point from "../../utils/point";
export default {
  name: "Map",
  components: { MapStationTip, MapWashroomTip },
  props: ["showMapStyle", "dataVivewmouver", "idMap", "fang","location"],
  data() {
    return {
      showLoad: true,
      dialogVisible: false,
      dialogVisibles: false,
      map: null,
      region: { lng: 118.52745, lat: 37.454672 },
      dataList: [],
      id: 1,
      tipType: 1, //1公厕 2 转运站
      pageX: "",
      pageY: "",
      pointArray: [],
      setTop: null,
      setBottom: null,
      playPion: true,
      timer: null,
      count: "",
      time: null,
      zhanshi: true,
      
    };
  },
  created() {


  },
  mounted() {
    this.initMap();
    this.getSiteDistribution();
    if(this.timer){
      clearInterval(this.timer)
    }
    setTimeout(() => {
      this.addMapIcon();
    }, 1500);

    
    

    // this.timer = setInterval(() => {
      
    // }, 1500);
    //倒计时
    const TIME_COUNT = 15;
    if (!this.time) {
      this.count = TIME_COUNT;
      //  this.show = false;
      this.time = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          // this.showLoad = false
          this.count--;
        } else if (this.count <= 0) {
          //  clearInterval(this.timer);
          this.count = TIME_COUNT + 1;
          this.count--;
          // this.showLoad = true
          this.getSiteDistribution();
           this.addMapIcon();
          // this.map.centerAndZoom(new BMap.Point('118.503916','37.453142'), 10);
          // this.map.setZoom(13)
        }
      }, 1000);
    }
  },
  updated() {},
  beforeDestroy() {
    clearInterval(this.time);
  },
  watch: {
    idMap(val) {
      console.log(val);
      if (val) {
        let lng = val.longitude,
        lnt = val.latitude;
        this.map.centerAndZoom(new BMap.Point(lng, lnt), 18);
      } else {
        console.log(123456);
        this.initMap();
        setTimeout(() => {
          this.addMapIcon();
        }, 1500);
      }
      
    },
  },
  methods: {
    initMap() {
      // let ax=window.event.pageX
      // let ay=window.event.pageY
      this.map = new BMap.Map("map", {
        enableMapClick: false,
        showLabel: false,
      });
      
       
      // setTimeout(function () {
      //   this.baiMap();
      // }, 500);
      this.map.setMapStyle({
        styleJson: custom,
        features: ["point", "water", "road", "building", "land"],
      }); //地图颜色
      if (this.showMapStyle) {
        this.map.setMapStyle({
          style: "dark",
          features: ["point", "water", "road", "building", "land"],
        }); //大数据地图颜色
      }
      //  this.map.setMapStyle({features:['point','water','road','building','land']}) //地图底层文字 建筑...
      this.map.centerAndZoom(new BMap.Point('118.503916','37.453142'), 10);
      this.map.disableDoubleClickZoom(); // 禁用双击放大
      this.map.enableScrollWheelZoom(true); // 启用滚轮放大缩小
      // this.baiMap(this.region.lng, this.region.lat);
      this.showIntMap(map);
      // this.map.panTo(new BMap.Point(this.region.lng, this.region.lnt));
    },
    // baiMap(lng, lnt) {
    //   // console.log(lng, lnt); // 开始是undefined
    //   //地图平移到中心点
    //   console.log(lng, lnt)
    //   this.playPion = false;
    //   const point = new BMap.Point(lng, lnt); // 创建点坐标
    //   let Thit = this;
    //   // this.map.centerAndZoom(new BMap.Point(lng,lnt),14);
    //   // this.map.panTo(new BMap.Point(lng, lnt));

    //   setTimeout(function () {
    //     //到同一坐标 不滑动
    //     Thit.map.panBy(new BMap.Point(lng, lnt));
    //     Thit.playPion = true;
    //     // Thit.map.centerAndZoom(new BMap.Point([]), 15);
    //   }, 1000);
    // },


    showIntMap() {
      let bdary = new BMap.Boundary();
      console.log(bdary)
      let Thit = this;

      bdary.get("东营区", function (rs) {
        Thit.map.clearOverlays();
        let pointArray = [];
        let ply = new BMap.Polygon(point[0], {

          strokeWeight: 2, //设置多边形边线线粗
      
          strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed
          strokeColor: "#4e6481", //设置多边形边线颜色
          fillOpacity: 0.6, //设置多边形填充颜色透明度0-1 注：标红的地放你们可以去掉看一下效果，自己体验一下
          fillColor: "rgba(120,159,169,0.5)",
      
          strokeOpacity: 1,
        }); //建立多边形覆盖物
        Thit.map.addOverlay(ply); //添加覆盖物
        // pointArray = pointArray.concat(ply.getPath());
        Thit.map.setViewport(pointArray); //调整视野
        Thit.map.setZoom(13)
      });
    },

    showMap() {
      this.map.setViewport(this.pointArray); //调整视野
    },
    addMapIcon() {
      console.log(this.map)
      this.dataList.forEach((item) => {
        const icon = new MapIcon(
          new BMap.Point(item.longitude, item.latitude),
          item.siteName, // 站点名称
          this.clickMapIcons.bind(this, item.id, item.siteType),
          this.mouseoverMapIcon.bind(this, item.id, item.siteType),
          this.mouseMapIcon.bind(this, item.id, item.siteType),
          item.siteType, // 站点类型
          item.status, // 公厕状态
          item.transferPointStatus, // 转运站状态
          item.workStatus, // 工作状态
          item.manStatus, // 男公厕状态
          item.womanStatus // 女公厕状态
        );
        
        this.map.addOverlay(icon);
      });
    },
    // 打开信息弹窗
    mouseoverMapIcon(id, type, val) {
      console.log(id,type)
     

      if (this.playPion) {
        if (!this.dataVivewmouver) {
          let lng;
          // console.log(this.dataList);
          if (type == 3) {
            lng = this.dataList.find((res) => {
              if (res.siteType == 3 && id == res.id) {
                this.id = id;
                console.log(res);
                return res;
              }
            });
          } else {
            lng = this.dataList.find((res) => {
              if (id == res.id) {
                this.id = id;
                console.log(res);
                return res;
              }
            });
          }

          let ax = window.event.pageX;
          let ay = window.event.pageY;
          if (ax < 650) {
            // console.log(ax);
            ax = ax + 800;

          } 
          console.log(ax);
          // console.log(ay);
          let docMaptan = document.getElementById("shroomTip");
          // animate(docMaptan,300)
          console.log(docMaptan);
          if (this.location) {
            docMaptan.style.left = ax - 1050 + "px";
          } else {
            docMaptan.style.left = ax - 650 + "px";
          }
          docMaptan.style.position = "absolute";
          
          docMaptan.style.top = "10%"
          
          // docMaptan.style.left = 750 + "px"
          // docMaptan.style.top = 5+ "%";
          docMaptan.style.zIndex = "999 !important"

          // this.showMap(this.pointArray)
          // this.baiMap(lng.longitude, lng.latitude);

          this.tipType = type;

          if (type == 3) {
            this.dialogVisibles = true;
            console.log('789456')

            // this.setTop = setTimeout(() => {
              
              
            // }, 1000);
            // this.playPion = true;
            return
          } 
          if (type == 2) {
            console.log('456123')
            
            
            this.setTop = setTimeout(() => {
              this.dialogVisible = true;
            }, 1000);
            // this.playPion = true;
            return
          }
          if (type == 1) {
            console.log('561234')
            
            this.setTop = setTimeout(() => {
              this.dialogVisible = true;
            }, 1000);
            // this.playPion = true;
            return
          }  

          
          
          
          
          
          
          // docMaptan.style.position = "absolute";
          // docMaptan.style.left = ax - 500 + "px";
          // docMaptan.style.top = 1 + "%";  
          
         
        }
      } else {
        return;
      }
    },
    mouseMapIcon(id, type) {
      // console.log(id,type)
      // let ax=window.event.pageX
      // let ay=window.event.pageY
      // if(ax<650){
      //   console.log(ax);
      //   ax=ax+800
      // }
      // let docMaptan=document.getElementById("shroomTip")
      // docMaptan.style.left=ax-750+'px'
      clearInterval(this.setTop);
      this.dialogVisible = false;
      this.dialogVisibles = false;
    },
    clickMapIcons(id, type) {
      //跳转到对应公厕详情
      console.log(type);
      if (type == 3) {
        this.$router.replace(`/stationlist/${id}`);
        return;
      }
      this.$router.replace(`/washroomlist/${id}`);
      // this.id = id;
      // this.tipType = type;
      this.dialogVisible = false;
    },
    async getSiteDistribution() {
      // 获取地图上站点分布数据
      let data = await axios.get("/data/index/find/siteDistribution");

      if (data) {
        this.dataList = data;
        console.log(this.dataList);
       
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mapbox,
#map {
  width: 100%;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 500px;
}
.mapbox {
  position: relative;
}

.countDown {
  position: absolute;
  top: 12px;
  right: 10%;
  z-index: 999999;
  color: #333333;
}
.map-data-tip {
  border: solid 1px #2f78ff;
  border-radius: 4px;
  width: 40px;
  // max-height: 300px;
  height: 220px ;
  padding: 5px;
  position: absolute;
  top: 20px;
  right: 10px !important;
  z-index: 100;
  background: white;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 12px;

  .tip-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9ecf7;
    .dian1 {
      width: 12px;
      height: 12px;
      background: #06d300;
      border-radius: 12px;
      margin-bottom: 10px;
    }
    .dian2 {
      width: 12px;
      height: 12px;
      background: #b4b4b4;
      border-radius: 12px;
      margin-bottom: 10px;
    }
    .dian3 {
      width: 12px;
      height: 12px;
      background: #ff4940;
      border-radius: 12px;
      margin-bottom: 10px;
    }
    .dian4 {
      width: 12px;
      height: 12px;
      background: #333333;
      border-radius: 12px;
      margin-bottom: 10px;
    }

    // img {
    //   margin-bottom: 10px;
    // }
    // span {
    //   margin: 5px ;
    // }
  }
  .tip-type:nth-child(4) {
    padding: 0;
    border-bottom: none;
  }
}
.map-data-top {
  border: solid 1px #2f78ff;
  border-radius: 4px;
  width: 280px;
  // max-height: 300px;
  height: 42px;
  // padding: 5px;
  position: absolute;
  top: 10px;
  left: 28%;
  z-index: 100;
  background: white;
  transform: translateX(-50%);
  display: flex;
  // flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
  .tip-type {
    display: flex;
    // flex-direction: column;
    align-items: center;

    // padding-bottom: 20px;
    // border-bottom: 1px solid #E9ECF7;
    .icont {
      padding-right: 5px;
    }
  }
  .tip-type:nth-child(4) {
    padding: 0;
    border-bottom: none;
  }
}

.shroomTip {
  width: 550px;
  position: absolute;
  top: 0%;
  left: 10%;
  z-index: 999 !important;
}
.shroomTips {
  width: 350px;
  position: absolute;
  top: 0%;
  left: 10%;
  z-index: 999;
}
</style>
