<template>
    <div class="map-tip-box">
        <!-- <table class="tip-table">
            <tr>
                <th colspan="2">
                    <span class="tip-name">{{info.toiletName}}</span>
                </th>
                <th>更新时间：</th>
                <th>
                    <span>{{info.updateTime}}</span>
                </th>
            </tr>
            <tr>
                <td>管养单位：</td>
                <td>{{info.managementDeptName}}</td>
                <td>当前状态：</td>
                <td>
                    <span class="state-close" v-if="info.status === 0">未开启</span>
                    <span class="state-open" v-if="info.status === 1">开放使用</span>
                    <span class="state-warn" v-if="info.status === 2">报警</span>
                </td>
            </tr>
            <tr>
                <td>联系人：</td>
                <td>{{info.staffName}}</td>
                <td>联系电话：</td>
                <td>{{info.staffPhone}}</td>
            </tr>
            <tr>
                <td>服务类型：</td>
                <td>{{info.serveName}}</td>
                <td>联系电话：</td>
                <td>{{info.deptPhone}}</td>
            </tr>
            <tr>
                <td>公厕地址：</td>
                <td colspan="3">{{info.location}}</td>
            </tr>
        </table> -->
                <el-row class="topRow">
        <el-col :span="24"><div class="grid-content tip-name">{{tInfo.transferPointName}}</div></el-col>
        
        </el-row>
       <el-row>
                <el-col :span="8"><div class="grid-content  name">清运车辆：</div></el-col>
                <el-col :span="16"><div class="grid-content  nameVal" :title="tInfo.vehicle">{{tInfo.vehicle}}</div></el-col>
        </el-row>
        <el-row>
                <el-col :span="8"><div class="grid-content  name">清运单位：</div></el-col>
                <el-col :span="16"><div class="grid-content  nameVal" :title="tInfo.managementDeptName">{{tInfo.managementDeptName}}</div></el-col>
        </el-row>
        <el-row>
                <el-col :span="8"><div class="grid-content name">当前状态：</div></el-col>
                <el-col :span="16"><div class="grid-content  nameVal" >
                        <span class="state-close" v-if="tInfo.status === 0">未开启</span>
                        <span class="state-open" v-if="tInfo.status === 1">正常运行</span>
                        <span class="state-warn" v-if="tInfo.status === 2">报警</span>
                </div></el-col>
        </el-row>
        <el-row >
        <el-col :span="8"><div class="grid-content  name">箱体1：</div></el-col>
        <el-col :span="16"><div class="grid-content  nameVal">
            <span class="state-open" v-if="boxItem[0] === 0">空</span>
                    <span  class="state-open" v-else-if="boxItem[0] === 1">半满</span>
                    <span class="state-warn" v-else-if="boxItem[0] === 2">满</span>
                    <span  v-else>暂无数据</span>
            </div></el-col>
        
        </el-row>
        <el-row>
            <el-col :span="8"><div class="grid-content  name">箱体2：</div></el-col>
            <el-col :span="16"><div class="grid-content  nameVal">
            <span class="state-open" v-if="boxItem[1] === 0">空</span>
                    <span class="state-open" v-else-if="boxItem[1] === 1">半满</span>
                    <span class="state-warn" v-else-if="boxItem[1] === 2">满</span>
                    <span  v-else>暂无数据</span>
            </div></el-col>
        </el-row>
         <el-row>
                <el-col :span="8"><div class="grid-content  name" style="color:#666666" >更新时间：</div></el-col>
                 <el-col :span="16"><div class="grid-content  nameVal">{{tInfo.updateTime}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="8"><div class="grid-content  name">转运站地址：</div></el-col>
            <el-col :span="16"><div class="grid-content  nameVal" :title="tInfo.location">{{tInfo.location}}</div></el-col>
        </el-row>
    </div>
</template>

<script>
import axios from "../../request"

export default {
    name: "MapWashroomTip",
    props: ["id"],
    data() {
        return {
            tInfo: {},
            boxItem:[],
            info: {},
            table: [
                {
                    value: "硫化氢(标准值：≤0.05ppm)",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "氨气(标准值：≤0.05ppm)",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "温度",
                    man: "待更新",
                    women: "待更新",
                },
                {
                    value: "湿度",
                    man: "待更新",
                    women: "待更新",
                },
            ]
        };
    },
    methods: {
        async getData(id){
            let data = await axios.get(`/data/index/transferPoint/findById/${id}`);
            if(data) {
                console.log(data);
                this.info = data;
                this.tInfo = data;
                this.boxItem[0] = data.cubage
                this.boxItem[1] = data.cubage1 
                this.table[0].man = data.manYdrothion
                this.table[0].women = data.womanYdrothion
                this.table[1].man = data.manAmmoniaGas
                this.table[1].women = data.womanAmmoniaGas
                this.table[2].man = data.manTemperature
                this.table[2].women = data.womanTemperature
                this.table[3].man = data.manHumidity
                this.table[3].women = data.womanHumidity
            }
        }
    },
    watch: {
        id(val){
            this.getData(val)
        }
    },
    mounted(){
        this.getData(this.id)
    }
};
</script>

<style scoped lang="scss">
.map-tip-box {
    // position: absolute;
    // z-index: 100;
    // left: 1000px;
    // top: 100px;
    border: solid 3px $blue;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    font-weight: bold;
}
// .tip-title {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border-bottom: solid 1px #E4E7ED;
//     padding-bottom: 5px;
//     margin-bottom: 5px;
//     @include font;
// }
// .tip-name {
//     border-left: solid 3px #ff610b;
//     padding-left: 5px;
// }

// .tip-table {
//     border-collapse: collapse;
//     th {
//         text-align: left;
//     }
//     th {
//         border-bottom: solid 1px #b7b7b7;
//     }
//     th:nth-child(1) {
//         color: grey;
//         width: 200px;
//     }
//     tr {
//         line-height: 3;
//         td:nth-child(2n-1) {
//             color: grey;
//         }
//         td:nth-child(2) {
//             width: 200px;
//         }
//     }
// }

.tip-name {
    border-left: solid 3px #FF610B;
    padding-left: 5px;
    font-weight: bold;
     white-space: nowrap;     
    overflow: hidden;        
    text-overflow: ellipsis;
}
.state-close{
    color: gray;
}
.state-open{
    color: #00da24;
}
.state-warn{
    color: #ff5955;
}
.el-row{
    margin: 10px 0 25px 0;
}
.topRow{
    padding: 10px 0px 15px 0px;
    border-bottom: solid 1px #e9ecf7;
}
.name{
   text-align: left;
   color: #666666;
}
.nameVal{
   color: #333333;
    white-space: nowrap;     
    overflow: hidden;        
    text-overflow: ellipsis;
    padding-right: 10px;
}

</style>
